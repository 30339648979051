import * as React from 'react';
import tw from 'twin.macro';
import { Language } from '../data/languages';
import { getHeaderNavItems, models } from '../../models';
import { Link } from '../utils/link';
import Logo from './logo';
import ContentWidth from './contentWidth';

type Props = {
  siteTitle: string;
  lang: Language;
  pathnameWithoutLang?: string;
};

const Header = ({ lang, pathnameWithoutLang }: Props) => {
  const isTop = pathnameWithoutLang === '/';
  const navItems = getHeaderNavItems();
  return (
    <ContentWidth>
      <header
        css={tw`flex flex-wrap items-center lg:items-stretch overflow-hidden`}
      >
        <div
          css={tw`py-4`}
          style={{ visibility: isTop ? 'hidden' : 'visible' }}
        >
          <div>
            <Link css={tw`hover:opacity-50 block`} to={models.pages.home.path}>
              <Logo />
            </Link>
          </div>
        </div>
        <label
          htmlFor="menu-toggle"
          css={tw`cursor-pointer lg:hidden block ml-auto z-50 lg:z-auto`}
        >
          <svg
            css={
              isTop
                ? tw`text-white fill-current`
                : tw`text-gray-900 fill-current`
            }
            viewBox="0 0 100 100"
            width="30"
            height="40"
          >
            <rect
              css={
                isTop
                  ? tw`text-white fill-current`
                  : tw`text-gray-900 fill-current`
              }
              y="30"
              width="100"
              height="6"
            />
            <rect
              css={
                isTop
                  ? tw`text-white fill-current`
                  : tw`text-gray-900 fill-current`
              }
              y="60"
              width="100"
              height="6"
            />
          </svg>
        </label>
        <input type="checkbox" css={tw`hidden`} id="menu-toggle" />

        <div
          css={
            isTop
              ? tw`hidden w-full lg:w-auto lg:flex m-0 p-0 list-none ml-auto bg-white bg-opacity-10`
              : tw`hidden w-full lg:w-auto lg:flex m-0 p-0 list-none ml-auto bg-gray-200`
          }
          id="menu"
        >
          <nav
            css={tw`h-full fixed w-full top-0 left-0 z-10 lg:z-auto lg:w-auto lg:static`}
          >
            <ul
              css={
                isTop
                  ? tw`flex flex-col justify-center items-center h-full lg:h-auto lg:flex-row lg:h-full m-0 p-0 list-none ml-auto bg-gray-400 lg:bg-white lg:bg-opacity-10`
                  : tw`flex flex-col justify-center items-center h-full lg:h-auto lg:flex-row lg:h-full m-0 p-0 list-none ml-auto bg-gray-200`
              }
            >
              {navItems.map((entry) => (
                <li
                  css={tw`flex items-stretch m-0 p-0 lg:first:pl-6 lg:last:pr-6 mb-6 lg:mb-0 h-auto lg:h-full`}
                  key={entry.path}
                >
                  <Link
                    css={tw`flex w-full items-center px-4 text-2xl lg:text-lg italic hover:opacity-50 text-title font-medium`}
                    to={entry.path}
                  >
                    <span css={isTop ? tw`lg:text-white` : tw``}>
                      {entry.displayName}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </header>
    </ContentWidth>
  );
};

export default Header;
