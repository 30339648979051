import * as React from 'react';
import tw from 'twin.macro';
import { ReactNode } from 'react';

const ResponsiveSvg = ({
  width,
  height,
  fixedWidth,
  children,
  margin,
}: {
  fixedWidth?: boolean;
  width: number;
  height: number;
  children: ReactNode;
  margin?: string;
}) => (
  <div
    style={{
      maxWidth: `${width}px`,
      margin: margin ?? 'auto',
      width: fixedWidth ? `${width}px` : '100%',
    }}
  >
    <div
      css={tw`inline-block relative w-full align-middle overflow-hidden`}
      style={{ paddingBottom: `${(height / width) * 100}%` }}
    >
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${width} ${height}`}
        css={tw`absolute top-0 left-0 w-full h-full`}
      >
        {children}
      </svg>
    </div>
  </div>
);

export default ResponsiveSvg;
