import * as React from 'react';
import { ReactNode } from 'react';
// import GatsbyTransitionLink from 'gatsby-plugin-transition-link';
import tw, { TwStyle } from 'twin.macro';
import { Link as GatsbyLink } from 'gatsby';

type LinkType = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
> & {
  children: ReactNode;
  to: string;
  duration?: number;
  fade?: boolean;
  cover?: boolean;
  paintDrip?: boolean;
  direction?: 'up' | 'right' | 'down' | 'left';
  activeClassName?: string;
  className?: string;
  css?: TwStyle;
};

// export const TransitionLink = ({
//   children,
//   to,
//   duration,
//   fade,
//   cover,
//   paintDrip,
//   direction,
//   activeClassName,
//   className,
//   css,
// }: LinkType) => (
//   <GatsbyTransitionLink
//     to={to}
//     fade={fade ?? false}
//     paintDrip={paintDrip ?? false}
//     cover={cover ?? false}
//     duration={duration ?? 0}
//     direction={direction ?? 'left'}
//     activeClassName={activeClassName ?? 'active'}
//     className={className ?? ''}
//     css={css ?? tw`p-10`}
//   >
//     {children}
//   </GatsbyTransitionLink>
// );

export const Link = ({
  children,
  to,
  activeClassName,
  className,
  css,
}: LinkType) => (
  <GatsbyLink
    to={to}
    activeClassName={activeClassName ?? 'active'}
    className={className ?? ''}
    css={css ?? tw``}
  >
    {children}
  </GatsbyLink>
);
