import tw, { TwStyle } from 'twin.macro';
import * as React from 'react';
import { ReactNode } from 'react';

const ContentWidth = ({
  children,
  css,
}: {
  children: ReactNode;
  css?: TwStyle;
}) => <div css={[tw`max-w-6xl mx-auto w-11/12`, css ?? null]}>{children}</div>;

export default ContentWidth;
