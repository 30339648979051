import * as React from 'react';
import tw from 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LogoSub from './logo-sub';
import { Link } from '../utils/link';
import { getFooterNavItems, socialMedias } from '../../models';
import ContentWidth from './contentWidth';

const Footer = () => {
  const navItems = getFooterNavItems();

  return (
    <footer
      css={tw`px-6 py-10 md:p-10 bg-gray-100 overflow-hidden`}
      id="footer"
    >
      <ContentWidth css={tw`w-full md:w-11/12`}>
        <div css={tw`md:flex md:items-center`}>
          <div>
            <Link to="/" css={tw`w-8/12 block hover:opacity-50 md:w-full`}>
              <LogoSub />
            </Link>
            <table css={tw`table-auto mt-10`}>
              <tbody>
                <tr css={tw`py-3 text-sm font-bold`}>
                  <td>メールアドレス:</td>
                  <td css={tw`pl-6 text-title`}>
                    <a
                      css={tw`hover:opacity-50`}
                      href="mailto:info@amerism.net"
                    >
                      info@amerism.net
                    </a>
                  </td>
                </tr>
                {/*<tr css={tw`py-3 text-sm font-bold`}>*/}
                {/*  <td>電話番号:</td>*/}
                {/*  <td css={tw`pl-6 text-title`}>080-0000-0000</td>*/}
                {/*</tr>*/}
                <tr css={tw`py-3 text-sm font-bold`}>
                  <td>所在地:</td>
                  <td css={tw`pl-6`}>東京都神田</td>
                </tr>
                <tr css={tw`py-3 text-sm font-bold`}>
                  <td>営業時間:</td>
                  <td css={tw`pl-6`}>
                    土・日 <span css={tw`text-title`}>9:00~18:00</span>
                  </td>
                </tr>
                <tr css={tw`py-3 text-sm font-bold`}>
                  <td>お休み:</td>
                  <td css={tw`pl-6`}>平日・祝日</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div css={tw`w-full md:flex md:ml-auto md:flex-wrap md:w-1/2`}>
            <Link
              css={tw`block md:flex md:inline-block md:ml-auto my-6 md:my-auto`}
              to={'/contact'}
            >
              <div
                css={tw`md:inline-block border-2 border-gray-400 bg-white rounded-md p-4 hover:underline font-bold leading-relaxed text-center md:text-left`}
              >
                <p>初回無料相談お申し込み</p>
                <p>お問い合わせはこちら!</p>
              </div>
            </Link>
            {navItems.length ? (
              <ul css={tw`flex flex-wrap md:justify-end my-6`}>
                {navItems.map((navItem, index) => (
                  <li
                    key={`footer-nav-${navItem.path}`}
                    css={tw`leading-loose text-title`}
                  >
                    <Link
                      css={tw`font-medium hover:opacity-50`}
                      to={navItem.path}
                    >
                      {navItem.displayName}
                    </Link>
                    {index === navItems.length - 1 ? null : (
                      <span css={tw`mx-2`}>/</span>
                    )}
                  </li>
                ))}
              </ul>
            ) : null}
            {Object.keys(socialMedias).length ? (
              <ul
                css={tw`flex ml-auto flex-wrap justify-center md:justify-start`}
              >
                {Object.entries(socialMedias).map(([key, socialMedia]) => (
                  <li
                    key={`footer-social-media-${socialMedia.url}`}
                    css={tw`flex items-center justify-center mr-3 last:mr-0 rounded-full`}
                    style={{ width: '30px', height: '30px' }}
                  >
                    <a
                      css={tw`hover:opacity-50 inline-flex`}
                      target="_blank"
                      rel="noopener"
                      href={`${socialMedia.url}/${socialMedia.username}`}
                    >
                      <FontAwesomeIcon
                        css={tw`text-2xl`}
                        icon={socialMedia.icon}
                      />
                    </a>
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
        </div>

        <small css={tw`text-center text-title italic block mt-8`}>
          <span>© {new Date().getFullYear()} Amerism All Rights Reserved</span>
        </small>
      </ContentWidth>
    </footer>
  );
};

export default Footer;
